import * as React from "react";
import Layout from "./Layout";
import { Tab, Tabs, Container } from "@material-ui/core";

import Profile from "../../components/Profile";
import { Paper, Box, Typography } from "@material-ui/core";
// import CheckoutButton from "../../components/CheckoutButton";
import Products from "../../components/Products";
import PaymentInfo from "../../components/PaymentInfo";

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`a11y-tabpanel-${index}`}
      aria-labelledby={`a11y-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const Index = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: unknown, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Layout>
      <div>
        <Tabs
          aria-labelledby="demo-a11y-tabs-automatic-label"
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          selectionFollowsFocus
          value={value}
        >
          <Tab
            label="お客様情報"
            aria-controls="a11y-tabpanel-0"
            id="a11y-tab-0"
          />

          <Tab label="国民権" aria-controls="a11y-tabpanel-1" id="a11y-tab-1" />

          <Tab
            label="お支払い情報"
            aria-controls="a11y-tabpanel-2"
            id="a11y-tab-2"
          />
        </Tabs>

        <TabPanel value={value} index={0}>
          <Container>
            <Typography variant="h4">お客様情報</Typography>
            <div style={{ marginTop: "2rem" }}>
              <Profile />
            </div>
          </Container>
        </TabPanel>

        <TabPanel value={value} index={1}>
          <Container>
            <Typography variant="h4">国民権を購入する</Typography>
            <div style={{ marginTop: "2rem" }}>
              <Paper>
                <Box p={4}>
                  <Products />
                </Box>
              </Paper>
            </div>
          </Container>
        </TabPanel>

        <TabPanel value={value} index={2}>
          <Container>
            <Typography variant="h4">お支払い情報</Typography>
            <div style={{ marginTop: "2rem" }}>
              <PaymentInfo />
            </div>
          </Container>
        </TabPanel>
      </div>
    </Layout>
  );
};

export default Index;
