/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateUserInput = {
  id?: string | null,
  sessionID?: string | null,
  paymentDate?: string | null,
  name?: string | null,
  gender?: string | null,
  email?: string | null,
  phone?: string | null,
  userID?: string | null,
  customer?: string | null,
  birthdate?: string | null,
  postCode?: string | null,
  subscription?: string | null,
  address?: string | null,
  deadline?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  amount?: string | null,
};

export type ModelUserConditionInput = {
  sessionID?: ModelStringInput | null,
  paymentDate?: ModelStringInput | null,
  name?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  customer?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  postCode?: ModelStringInput | null,
  subscription?: ModelStringInput | null,
  address?: ModelStringInput | null,
  deadline?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  amount?: ModelStringInput | null,
  and?: Array< ModelUserConditionInput | null > | null,
  or?: Array< ModelUserConditionInput | null > | null,
  not?: ModelUserConditionInput | null,
};

export type ModelStringInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export enum ModelAttributeTypes {
  binary = "binary",
  binarySet = "binarySet",
  bool = "bool",
  list = "list",
  map = "map",
  number = "number",
  numberSet = "numberSet",
  string = "string",
  stringSet = "stringSet",
  _null = "_null",
}


export type ModelSizeInput = {
  ne?: number | null,
  eq?: number | null,
  le?: number | null,
  lt?: number | null,
  ge?: number | null,
  gt?: number | null,
  between?: Array< number | null > | null,
};

export type User = {
  __typename: "User",
  id?: string,
  sessionID?: string | null,
  paymentDate?: string | null,
  name?: string | null,
  gender?: string | null,
  email?: string | null,
  phone?: string | null,
  userID?: string | null,
  customer?: string | null,
  birthdate?: string | null,
  postCode?: string | null,
  subscription?: string | null,
  address?: string | null,
  deadline?: string | null,
  createdAt?: string,
  updatedAt?: string | null,
  amount?: string | null,
  owner?: string | null,
};

export type UpdateUserInput = {
  id: string,
  sessionID?: string | null,
  paymentDate?: string | null,
  name?: string | null,
  gender?: string | null,
  email?: string | null,
  phone?: string | null,
  userID?: string | null,
  customer?: string | null,
  birthdate?: string | null,
  postCode?: string | null,
  subscription?: string | null,
  address?: string | null,
  deadline?: string | null,
  createdAt?: string | null,
  updatedAt?: string | null,
  amount?: string | null,
};

export type DeleteUserInput = {
  id?: string | null,
};

export type ModelUserFilterInput = {
  id?: ModelIDInput | null,
  sessionID?: ModelStringInput | null,
  paymentDate?: ModelStringInput | null,
  name?: ModelStringInput | null,
  gender?: ModelStringInput | null,
  email?: ModelStringInput | null,
  phone?: ModelStringInput | null,
  userID?: ModelStringInput | null,
  customer?: ModelStringInput | null,
  birthdate?: ModelStringInput | null,
  postCode?: ModelStringInput | null,
  subscription?: ModelStringInput | null,
  address?: ModelStringInput | null,
  deadline?: ModelStringInput | null,
  createdAt?: ModelStringInput | null,
  updatedAt?: ModelStringInput | null,
  amount?: ModelStringInput | null,
  and?: Array< ModelUserFilterInput | null > | null,
  or?: Array< ModelUserFilterInput | null > | null,
  not?: ModelUserFilterInput | null,
};

export type ModelIDInput = {
  ne?: string | null,
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  contains?: string | null,
  notContains?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
  attributeExists?: boolean | null,
  attributeType?: ModelAttributeTypes | null,
  size?: ModelSizeInput | null,
};

export type ModelUserConnection = {
  __typename: "ModelUserConnection",
  items?:  Array<User | null > | null,
  nextToken?: string | null,
};

export type ModelStringKeyConditionInput = {
  eq?: string | null,
  le?: string | null,
  lt?: string | null,
  ge?: string | null,
  gt?: string | null,
  between?: Array< string | null > | null,
  beginsWith?: string | null,
};

export enum ModelSortDirection {
  ASC = "ASC",
  DESC = "DESC",
}


export type CreateUserMutationVariables = {
  input?: CreateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type CreateUserMutation = {
  createUser?:  {
    __typename: "User",
    id: string,
    sessionID?: string | null,
    paymentDate?: string | null,
    name?: string | null,
    gender?: string | null,
    email?: string | null,
    phone?: string | null,
    userID?: string | null,
    customer?: string | null,
    birthdate?: string | null,
    postCode?: string | null,
    subscription?: string | null,
    address?: string | null,
    deadline?: string | null,
    createdAt: string,
    updatedAt?: string | null,
    amount?: string | null,
    owner?: string | null,
  } | null,
};

export type UpdateUserMutationVariables = {
  input?: UpdateUserInput,
  condition?: ModelUserConditionInput | null,
};

export type UpdateUserMutation = {
  updateUser?:  {
    __typename: "User",
    id: string,
    sessionID?: string | null,
    paymentDate?: string | null,
    name?: string | null,
    gender?: string | null,
    email?: string | null,
    phone?: string | null,
    userID?: string | null,
    customer?: string | null,
    birthdate?: string | null,
    postCode?: string | null,
    subscription?: string | null,
    address?: string | null,
    deadline?: string | null,
    createdAt: string,
    updatedAt?: string | null,
    amount?: string | null,
    owner?: string | null,
  } | null,
};

export type DeleteUserMutationVariables = {
  input?: DeleteUserInput,
  condition?: ModelUserConditionInput | null,
};

export type DeleteUserMutation = {
  deleteUser?:  {
    __typename: "User",
    id: string,
    sessionID?: string | null,
    paymentDate?: string | null,
    name?: string | null,
    gender?: string | null,
    email?: string | null,
    phone?: string | null,
    userID?: string | null,
    customer?: string | null,
    birthdate?: string | null,
    postCode?: string | null,
    subscription?: string | null,
    address?: string | null,
    deadline?: string | null,
    createdAt: string,
    updatedAt?: string | null,
    amount?: string | null,
    owner?: string | null,
  } | null,
};

export type GetUserQueryVariables = {
  id?: string,
};

export type GetUserQuery = {
  getUser?:  {
    __typename: "User",
    id: string,
    sessionID?: string | null,
    paymentDate?: string | null,
    name?: string | null,
    gender?: string | null,
    email?: string | null,
    phone?: string | null,
    userID?: string | null,
    customer?: string | null,
    birthdate?: string | null,
    postCode?: string | null,
    subscription?: string | null,
    address?: string | null,
    deadline?: string | null,
    createdAt: string,
    updatedAt?: string | null,
    amount?: string | null,
    owner?: string | null,
  } | null,
};

export type ListUsersQueryVariables = {
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListUsersQuery = {
  listUsers?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      sessionID?: string | null,
      paymentDate?: string | null,
      name?: string | null,
      gender?: string | null,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      customer?: string | null,
      birthdate?: string | null,
      postCode?: string | null,
      subscription?: string | null,
      address?: string | null,
      deadline?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      amount?: string | null,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListPaymentByUserQueryVariables = {
  userID?: string | null,
  createdAt?: ModelStringKeyConditionInput | null,
  sortDirection?: ModelSortDirection | null,
  filter?: ModelUserFilterInput | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListPaymentByUserQuery = {
  listPaymentByUser?:  {
    __typename: "ModelUserConnection",
    items?:  Array< {
      __typename: "User",
      id: string,
      sessionID?: string | null,
      paymentDate?: string | null,
      name?: string | null,
      gender?: string | null,
      email?: string | null,
      phone?: string | null,
      userID?: string | null,
      customer?: string | null,
      birthdate?: string | null,
      postCode?: string | null,
      subscription?: string | null,
      address?: string | null,
      deadline?: string | null,
      createdAt: string,
      updatedAt?: string | null,
      amount?: string | null,
      owner?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type OnCreateUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnCreateUserSubscription = {
  onCreateUser?:  {
    __typename: "User",
    id: string,
    sessionID?: string | null,
    paymentDate?: string | null,
    name?: string | null,
    gender?: string | null,
    email?: string | null,
    phone?: string | null,
    userID?: string | null,
    customer?: string | null,
    birthdate?: string | null,
    postCode?: string | null,
    subscription?: string | null,
    address?: string | null,
    deadline?: string | null,
    createdAt: string,
    updatedAt?: string | null,
    amount?: string | null,
    owner?: string | null,
  } | null,
};

export type OnUpdateUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnUpdateUserSubscription = {
  onUpdateUser?:  {
    __typename: "User",
    id: string,
    sessionID?: string | null,
    paymentDate?: string | null,
    name?: string | null,
    gender?: string | null,
    email?: string | null,
    phone?: string | null,
    userID?: string | null,
    customer?: string | null,
    birthdate?: string | null,
    postCode?: string | null,
    subscription?: string | null,
    address?: string | null,
    deadline?: string | null,
    createdAt: string,
    updatedAt?: string | null,
    amount?: string | null,
    owner?: string | null,
  } | null,
};

export type OnDeleteUserSubscriptionVariables = {
  owner?: string | null,
};

export type OnDeleteUserSubscription = {
  onDeleteUser?:  {
    __typename: "User",
    id: string,
    sessionID?: string | null,
    paymentDate?: string | null,
    name?: string | null,
    gender?: string | null,
    email?: string | null,
    phone?: string | null,
    userID?: string | null,
    customer?: string | null,
    birthdate?: string | null,
    postCode?: string | null,
    subscription?: string | null,
    address?: string | null,
    deadline?: string | null,
    createdAt: string,
    updatedAt?: string | null,
    amount?: string | null,
    owner?: string | null,
  } | null,
};
