import React from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { loadStripe } from "@stripe/stripe-js";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
} from "@material-ui/core";
import {
  GetUserQuery,
  ListPaymentByUserQuery,
  ListPaymentByUserQueryVariables,
  ModelSortDirection,
} from "../API";
import Typography from "@material-ui/core/Typography";
import { format, addYears } from "date-fns";
import ja from "date-fns/locale/ja";
import { getQuery, keyQuery } from "../apiTypeUtil";
console.log("process", process.env.GATSBY_STRIPE_PUBLIC_KEY);
const key = process.env.GATSBY_STRIPE_PUBLIC_KEY;
console.log("key", key);

const stripePromise = loadStripe(key);

type Props = {
  name: string;
  priceId: string;
  price: string;
};

export const dateYear = (dateString: string) => {
  return format(new Date(dateString), "YoMMMdo", {
    locale: ja,
  });
};

const CheckoutButton = (props: Props) => {
  const { name, priceId, price } = props;
  const [loading, setLoading] = React.useState(true);
  const [payment, setPayment] = React.useState<GetUserQuery["getuser"] | null>(
    null
  );

  const redirectToCheckout = async () => {
    const fetchSession = async () => {
      const apiName = "stripeCheckout";
      const apiEndpoint = "/checkout";
      const cognito = await Auth.currentUserInfo();
      console.log("cognito:", cognito);

      const res = await keyQuery<
        ListPaymentByUserQuery,
        ListPaymentByUserQueryVariables
      >("listPaymentByUser", { userID: cognito.username });
      console.log("res", res);
      const payments = res?.data?.listPaymentByUser?.items;
      if (payments?.[0]) {
        const payment = payments[0];
        console.log("payment", payment);
        if (payment) {
          const body = {
            rootUrl: window.location.href,
            quantity: 1,
            client_reference_id: payment.id,
            priceId,
          };
          const session = await API.post(apiName, apiEndpoint, { body });
          return session;
        }
      }
    };
    const session = await fetchSession();
    console.log("session", session);
    if (session) {
      const sessionId = session.id;
      const stripe = await stripePromise;
      if (stripe) {
        stripe.redirectToCheckout({ sessionId });
      } else {
        alert("エラーが発生しました。");
      }
    }
  };

  React.useEffect(() => {
    async function getPayment() {
      try {
        const cognito = await Auth.currentUserInfo();

        console.log("cognito:", cognito);
        if (!cognito) {
          setLoading(false);
          console.error("auth error");
        }

        const { username } = cognito;
        try {
          const res = await keyQuery<
            ListPaymentByUserQuery,
            ListPaymentByUserQueryVariables
          >("listPaymentByUser", {
            userID: username,
            sortDirection: ModelSortDirection.DESC,
          });
          console.log("res", res);
          const payments = res?.data?.listPaymentByUser?.items;

          if (payments?.[0]) {
            const payment = payments[0];
            console.log("payment", payment);
            if (payment) {
              console.log("payment", payment);
              setPayment(payment);
            }
          }
          setLoading(false);
        } catch {
          setLoading(false);
        }
        // const resPayment = (await API.graphql(
        //   graphqlOperation(queries.getPayment, { id: userID })
        // )) as GraphQLResult<GetPaymentQuery>;

        setLoading(false);
      } catch {
        setLoading(false);
      }
    }
    getPayment();
  }, []);

  return (
    <>
      {loading ? (
        <CircularProgress />
      ) : (
        <Card
          style={{
            background: payment?.amount === price + "" ? "#fce4ec" : "#fff",
          }}
        >
          <CardContent>
            <Typography
              variant="h6"
              component="h5"
              style={{ fontWeight: 700, marginBottom: 8 }}
            >
              {name}
            </Typography>
            {payment?.amount !== price + "" ? (
              <div style={{ marginLeft: "1rem" }}>
                <Typography variant="body2">{`${price} 円 / 年（税込）`}</Typography>
              </div>
            ) : (
              <div style={{ marginLeft: "1rem" }}>
                <Typography variant="body2">{`${price} 円 / 年（税込）`}</Typography>

                <Typography color="textSecondary" style={{ color: "#C83A3A" }}>
                  購入済み
                </Typography>

                <Typography variant="body2" component="p">
                  {`購入日 ${dateYear(payment?.updatedAt)}`}
                </Typography>

                <Typography variant="body2" component="p">
                  {`有効期限 ${dateYear(payment?.deadline)}`}
                </Typography>
              </div>
            )}
          </CardContent>
          <CardActions>
            <Button
              style={{ marginLeft: "auto" }}
              color="primary"
              variant="contained"
              onClick={redirectToCheckout}
            >
              購入
            </Button>
          </CardActions>
        </Card>
      )}
    </>
  );
};

export default CheckoutButton;
