import React from "react";
import { graphql, StaticQuery } from "gatsby";
import CheckoutButton from "./CheckoutButton";
import { Box, Typography } from "@material-ui/core";
export default function Products() {
  return (
    <>
      <Typography variant="h6">国民権コース</Typography>
      <StaticQuery
        query={graphql`
          query ProductPrices {
            prices: allStripePrice(
              filter: { active: { eq: true } }
              sort: { fields: [unit_amount] }
            ) {
              edges {
                node {
                  id
                  active
                  currency
                  unit_amount
                  product {
                    active
                    id
                    name
                  }
                }
              }
            }
          }
        `}
        render={({ prices }) => (
          <>
            {prices.edges.map((item, key) => {
              console.log("item:", item);
              return (
                <span key={key}>
                  {item.node.product.active && (
                    <Box m={2}>
                      <CheckoutButton
                        price={item.node.unit_amount}
                        name={item.node.product.name}
                        priceId={item.node.id}
                      />
                    </Box>
                  )}
                </span>
              );
            })}
          </>
        )}
      />
    </>
  );
}
