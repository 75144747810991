import React from "react";
import { Auth } from "aws-amplify";
import { useForm, Controller } from "react-hook-form";
import { Grid, TextField, Button, Paper, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: "left",
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

type FormType = {
  code: string;
};

const defaultValues = {
  code: "",
};

const Profile = () => {
  const classes = useStyles();
  const { control, handleSubmit, reset } = useForm<FormType>({
    defaultValues,
  });
  const onSubmit = async (data: FormType) => {
    if (data) {
      const { code } = data;
      try {
        await Auth.verifyCurrentUserAttributeSubmit("email", code);
        alert("更新されました。");
        window.location.href = "/member";
        // onChange();
      } catch (error) {
        alert("認証コードが正しくありません。");
      }
    }
  };

  return (
    <Paper>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box m={2}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Controller
                name="code"
                control={control}
                defaultValue=""
                label="認証コード"
                required
                as={<TextField variant="outlined" />}
              />
            </Grid>

            <Grid item xs={12}>
              <Grid container justify="flex-end">
                <div className={classes.wrapper}>
                  <Button type="submit" variant="contained" color="primary">
                    送信
                  </Button>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Paper>
  );
};

export default Profile;
