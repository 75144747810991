import * as React from "react";
import { API, Auth, graphqlOperation } from "aws-amplify";
import { Button, CircularProgress } from "@material-ui/core";
import { Link } from "gatsby";
import { Paper, Box, Typography } from "@material-ui/core";
// import CheckoutButton from "../../components/CheckoutButton";
import {
  GetPaymentQuery,
  GetUserQuery,
  ListPaymentByUserQuery,
  ListPaymentByUserQueryVariables,
} from "../API";
import { getQuery, keyQuery } from "../apiTypeUtil";

type TabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const PaymentInfo = () => {
  const [paymentPortal, setPaymentPortal] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  const fetchPortal = async (sessionID: string) => {
    const apiName = "stripeCheckout";
    const apiEndpoint = "/customer-portal";
    const body = {
      returnUrl: window.location.href,
      sessionID,
    };
    try {
      const res = await API.post(apiName, apiEndpoint, { body });
      return res;
    } catch {
      alert("エラーが発生しました。");
    }
  };

  async function getSessionID() {
    const cognito = await Auth.currentUserInfo();
    if (!cognito) return null;

    const { username } = cognito;
    try {
      const res = await keyQuery<
        ListPaymentByUserQuery,
        ListPaymentByUserQueryVariables
      >("listPaymentByUser", { userID: username });
      console.log("res", res);
      const payments = res?.data?.listPaymentByUser?.items;
      if (payments?.[0]) {
        return payments?.[0].sessionID;
      }
      // const res = await getQuery<GetUserQuery>("User", username);
      // const payment = res?.data?.getUser;
      // console.log("res", payment);
    } catch {
      setLoading(false);
    }
  }

  const setPortalURL = React.useCallback(async () => {
    setLoading(true);
    const sessionID = await getSessionID();
    if (sessionID) {
      const res = await fetchPortal(sessionID);
      if (res?.url) {
        setPaymentPortal(res.url);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, []);

  React.useEffect(() => {
    setPortalURL();
  }, [setPortalURL]);
  return (
    <Paper>
      <Box p={4}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {paymentPortal ? (
              <>
                <Button
                  to={paymentPortal}
                  variant="outlined"
                  color="primary"
                  component={Link}
                >
                  お支払い情報の確認
                </Button>
                <Typography variant="body2" component="p">
                  別サイトに遷移します。
                </Typography>
              </>
            ) : (
              <Typography>購入履歴がありません。</Typography>
            )}
          </>
        )}
      </Box>
    </Paper>
  );
};

export default PaymentInfo;
