import React from "react";
import { Auth } from "aws-amplify";
import { useForm, Controller } from "react-hook-form";
import {
  Grid,
  TextField,
  Button,
  Paper,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Select from "react-select";
import { createQuery, keyQuery } from "../apiTypeUtil";
import {
  CreateUserMutation,
  CreateUserMutationVariables,
  ListPaymentByUserQuery,
  ListPaymentByUserQueryVariables,
  ModelSortDirection,
  UpdateUserMutation,
  UpdateUserMutationVariables,
} from "API";
import InputCode from "./InputCode";

const options = [
  { value: "男性", label: "男性" },
  { value: "女性", label: "女性" },
];

const useStyles = makeStyles((theme) => ({
  wrapper: {
    textAlign: "left",
    margin: theme.spacing(1),
    position: "relative",
  },
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
}));

type FormType = {
  id: string;
  name: string;
  email: string;
  gender: typeof options[0];
  birthdate: string;
  postCode: string;
  address: string;
  userID: string;
};

const defaultValues = {
  id: "",
  name: "",
  email: "",
  gender: options[0],
  birthdate: "",
  postCode: "",
  address: "",
  userID: "",
};

const Profile = () => {
  const classes = useStyles();
  const { control, handleSubmit, reset } = useForm<FormType>({
    defaultValues,
  });
  // let currentEmail: string;
  const [pending, setPending] = React.useState(false);
  const [inputCode, setInputCode] = React.useState(false);

  const [currentEmail, setCurrentEmail] = React.useState("");

  React.useEffect(() => {
    async function get() {
      try {
        const currentUserInfo = await Auth.currentUserInfo();
        console.log("currentUserInfo", currentUserInfo);
        const { username, attributes } = currentUserInfo;

        console.log("username", username);

        const res = await keyQuery<
          ListPaymentByUserQuery,
          ListPaymentByUserQueryVariables
        >("listPaymentByUser", {
          userID: username,
          sortDirection: ModelSortDirection.DESC,
        });
        console.log("res", res);
        const payments = res?.data?.listPaymentByUser?.items;
        if (payments?.[0]) {
          const payment = payments[0];
          console.log("payment", payment);
          if (payment) {
            const {
              __typename,
              sessionID,
              paymentDate,
              gender,
              deadline,
              createdAt,
              updatedAt,
              birthdate,
              ...profile
            } = payment;

            setCurrentEmail(attributes?.email ?? "");

            console.log("profile:", profile);
            const genderObject =
              options.find((item) => item.value === gender) || options[0];
            reset({
              ...profile,
              birthdate: new Date(birthdate).toISOString().substring(0, 10),
              userID: username,
              email: attributes?.email ?? "",
              gender: genderObject,
            });
          } else {
            console.log("aa", username, attributes);
            await createBlankPayment(username, attributes, reset);
          }
        } else {
          console.log("bb", username, attributes);
          setCurrentEmail(attributes?.email ?? "");

          await createBlankPayment(username, attributes, reset);
        }
        console.log("currentUserInfo:", currentUserInfo);
      } catch (err) {
        console.log("error fetching user info: ", err);
      }
    }
    void get();
  }, [reset]);

  const onSubmit = async (data: FormType) => {
    if (data) {
      const { gender, birthdate, ..._value } = data;
      console.log("value", _value);

      const value = {
        ..._value,
        birthdate: birthdate ? new Date(birthdate).toISOString() : null,
        gender: gender.value,
      };
      console.log("value:", value);
      setPending(true);
      await createQuery<
        UpdateUserMutation,
        UpdateUserMutationVariables["input"]
      >("User", value, true);

      if (currentEmail !== data.email) {
        const user = await Auth.currentAuthenticatedUser();
        await Auth.updateUserAttributes(user, {
          email: data.email,
        });
        alert(
          "メールアドレスを変更しました。新しいメールアドレスに認証コードをお送りました。再度ログインして認証コードを入力してください。"
        );
        setInputCode(true);
      } else {
        alert("変更しました。");
      }
      setPending(false);
    }
  };

  return (
    <Paper>
      {!inputCode && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box m={2}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Controller
                  name="id"
                  control={control}
                  defaultValue=""
                  label="id"
                  required
                  render={(props) => null}
                />
                <Controller
                  name="userID"
                  control={control}
                  defaultValue=""
                  label="ユーザネーム"
                  required
                  as={
                    <TextField
                      variant="outlined"
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  defaultValue=""
                  label="氏名"
                  required
                  as={<TextField variant="outlined" fullWidth />}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  label="性別"
                  name="gender"
                  control={control}
                  options={options}
                  as={<Select />}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  label="メールアドレス"
                  as={<TextField variant="outlined" fullWidth />}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  name="phone"
                  control={control}
                  defaultValue=""
                  label="電話番号"
                  as={<TextField variant="outlined" fullWidth />}
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  type="date"
                  name="birthdate"
                  control={control}
                  defaultValue=""
                  label="誕生日"
                  as={
                    <TextField
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  }
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  label="郵便番号"
                  name="postCode"
                  control={control}
                  placeholder="例 100-0000"
                  defaultValue=""
                  as={<TextField variant="outlined" fullWidth />}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <Controller
                  label="住所"
                  name="address"
                  control={control}
                  defaultValue=""
                  as={<TextField variant="outlined" fullWidth />}
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <Grid container justify="flex-end">
                  <div className={classes.wrapper}>
                    <Button type="submit" variant="contained" color="primary">
                      更新
                    </Button>
                    {pending && (
                      <CircularProgress
                        size={24}
                        className={classes.buttonProgress}
                      />
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </form>
      )}
      {inputCode && <InputCode onChange={() => setInputCode(false)} />}
    </Paper>
  );
};

export default Profile;
async function createBlankPayment(
  username: string,
  attributes: any,
  reset: (
    values?:
      | {
          id?: string | undefined;
          name?: string | undefined;
          email?: string | undefined;
          gender?:
            | { value?: string | undefined; label?: string | undefined }
            | undefined;
          birthdate?: string | undefined;
          postCode?: string | undefined;
          address?: string | undefined;
          userID?: string | undefined;
        }
      | undefined,
    omitResetState?:
      | Partial<{
          errors: boolean;
          isDirty: boolean;
          isSubmitted: boolean;
          touched: boolean;
          isValid: boolean;
          submitCount: boolean;
          dirtyFields: boolean;
        }>
      | undefined
  ) => void
) {
  const _res = await createQuery<
    CreateUserMutation,
    CreateUserMutationVariables["input"]
  >(
    "User",
    {
      userID: username ?? "",
      email: attributes?.email ?? "",
    },
    false
  );
  const payment = _res?.data?.createUser;
  if (payment) {
    reset({
      id: payment.id,
      userID: username,
      email: attributes?.email ?? "",
    });
  }
}
