/* eslint @typescript-eslint/no-unsafe-member-access: 0 */
/* eslint @typescript-eslint/no-unsafe-assignment: 0 */
/* eslint @typescript-eslint/no-unsafe-return: 0 */
/* eslint @typescript-eslint/no-unsafe-call: 0 */
import { API, graphqlOperation, Auth } from "aws-amplify";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { pascalCase } from "change-case";
import gql from "graphql-tag";
import { merge } from "lodash";
import * as _mutations from "./graphql/mutations";
import * as _queries from "./graphql/queries";
import { GRAPHQL_AUTH_MODE } from "@aws-amplify/api-graphql/lib/types";

type StringKeyObject = {
  // 今回はstring
  [key: string]: any;
};

const queries: StringKeyObject = _queries;
const mutations: StringKeyObject = _mutations;

export async function addToGroup(username: string, groupname: string) {
  const apiName = "AdminQueries";
  const path = "/addUserToGroup";
  const myInit = {
    body: {
      username,
      groupname,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return await API.post(apiName, path, myInit);
}

export async function singOut(username: string) {
  const apiName = "AdminQueries";
  const path = "/signUserOut";
  const myInit = {
    body: {
      username,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`,
    },
  };
  try {
    const res = await API.post(apiName, path, myInit);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

// eslint-disab
export async function matchGroup(ckGroup: string) {
  const cuser = await Auth.currentAuthenticatedUser();

  const groups = cuser.signInUserSession.accessToken.payload["cognito:groups"];

  if (groups) {
    const match = groups.filter((group: string) => group.includes(ckGroup));
    return match.length > 0;
  } else {
    return false;
  }
}

export async function getQuery<Result>(
  target: string,
  id: string,
  noError = false
) {
  const queryName = `get${pascalCase(target)}`;
  try {
    const res = (await API.graphql(
      graphqlOperation(queries[queryName], { id })
    )) as GraphQLResult<Result>;
    return res;
    // console.log(`getQuery ${target} ${id}`, data);
  } catch (error) {
    if (!noError) {
    }
    console.error(error);
    return null;
  }
}

export async function listQuery<Result, Params>(
  target: string,
  variable?: Params
) {
  let res;
  const queryName = `list${pascalCase(target)}s`;
  try {
    res = (await API.graphql(
      //@ts-ignore
      graphqlOperation(queries[queryName], mergedVariable as Params)
    )) as GraphQLResult<Result>;
    //@ts-ignore
    console.log(`listQuery ${target}`, res, variable);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function keyQuery<Result, Params>(
  queryName: string,
  variable: Params
) {
  let res;
  console.log(queries[queryName]);
  try {
    res = (await API.graphql(
      //@ts-ignore
      graphqlOperation(queries[queryName], variable)
    )) as GraphQLResult<Result>;
    // console.log(`${queryName}:`, res);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function getInput<Params>(variable: any, noGroup?: boolean) {
  let input;
  if (!noGroup) {
    input = { ...variable };
  } else {
    input = { ...variable };
  }

  if (variable.id) {
    input.updatedAt = new Date().toISOString();
  } else {
    delete input.id;
    // input.id = uuid.v4();
    input.createdAt = new Date().toISOString();
    input.updatedAt = new Date().toISOString();
  }
  return input as Params;
}

export async function getQueryName(target: string, variable: any) {
  const id = variable.id;
  let action = "create";

  if (id) {
    action = "update";
  }
  return `${action}${pascalCase(target)}`;
}

export async function createQuery<Result, Params>(
  target: string,
  variable: Params,
  isUpdate: boolean
) {
  const action = isUpdate ? "update" : "create";
  const queryName = `${action}${pascalCase(target)}`;
  let input = {};
  if (isUpdate) {
    input = {
      ...variable,
      updatedAt: new Date().toISOString(),
    };
  } else {
    input = {
      ...variable,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
    };
  }
  try {
    const res = (await API.graphql(
      graphqlOperation(mutations[queryName], {
        input,
      })
    )) as GraphQLResult<Result>;
    console.log(`create ${target}:`, res);

    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function customQuery({
  query,
  variables,
  isPublic,
}: {
  query: string;
  isPublic: boolean;
  variables: any;
}) {
  let res;
  try {
    //@ts-ignore
    res = await API.graphql({
      // @ts-ignore

      query: query ? gql(query) : queries[queryName],

      variables,
      authMode: isPublic
        ? GRAPHQL_AUTH_MODE.API_KEY
        : GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS,
    });
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function doFunction<Result, Params>(
  funcName: string,
  variable: Params
) {
  const queryName = funcName;
  try {
    const res = (await API.graphql(
      //@ts-ignore
      graphqlOperation(mutations[queryName], variable)
    )) as GraphQLResult<Result>;

    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function createQueries<Result, Params>(
  target: string,
  variables: Params[],
  noGroup?: boolean
) {
  const tasks = [] as GraphQLResult<Result>[];

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  variables.forEach(async function (variable) {
    const queryName = await getQueryName(target, variable);

    const input = await getInput<Params>(variable);
    console.log(target, queryName, input);

    const task = API.graphql(
      //@ts-ignore
      graphqlOperation(mutations[queryName], {
        input,
      })
    ) as GraphQLResult<Result>;

    tasks.push(task);
  });

  let resAll;
  try {
    return await Promise.all(tasks);
    console.log("result:", resAll);
  } catch (error) {
    console.error(target, error);
  }
}

export async function deleteQuery<Result>(target: string, id: string) {
  let res;
  const queryName = `delete${pascalCase(target)}`;
  try {
    res = (await API.graphql(
      // @ts-ignore
      graphqlOperation(mutations[queryName], { input: { id } })
    )) as GraphQLResult<Result>;
    console.log("user:", res);
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

export async function softDeleteQueries<Result>(target: string, id: string) {
  let res;
  try {
    res = (await createQuery<Result, { id: string; delete: boolean }>(target, {
      id,
      delete: true,
    })) as GraphQLResult<Result>;
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
}

// 手動で作成したGraphQLクエリに対応
export async function reportQuery(queryName: string, variable: any) {
  let res;

  try {
    //@ts-ignore
    res = await API.graphql(
      // @ts-ignore
      graphqlOperation(reportQueries[queryName], mergedVariable)
    );
    //@ts-ignore
    // nextTokenを利用できるようにitemsまで指定していません
    return res.data[queryName];
  } catch (error) {
    console.error(error);
    return null;
  }
}

// 手動で作成したGraphQLクエリに対応
export async function taskQuery(queryName: string, variable: any) {
  let res;

  try {
    const mergedVariable = merge(variable, {
      filter: { delete: { ne: true } },
    });
    //@ts-ignore
    res = await API.graphql(
      // @ts-ignore
      graphqlOperation(taskQueries[queryName], mergedVariable)
    );
    //@ts-ignore
    // nextTokenを利用できるようにitemsまで指定していません

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
    return res.data[queryName];
  } catch (error) {
    console.error(error);
    return null;
  }
}
